import React, { useState, useLayoutEffect, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { X } from "react-feather";
import { useLocation } from "@reach/router";
import Img from "gatsby-image";
import router from "@utils/routes.json";

const Navbar = () => {
  const img = useStaticQuery(graphql`
    query NavbarQuery {
      darkLogo: file(relativePath: { eq: "logo/logo-dark.png" }) {
        childImageSharp {
          fixed(width: 90, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      lightLogo: file(relativePath: { eq: "logo/logo-light.png" }) {
        childImageSharp {
          fixed(width: 90, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      contactUsBtn: file(relativePath: { eq: "contact-us-btn.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hamburgerDark: file(relativePath: { eq: "icon/hamburger-dark.svg" }) {
        publicURL
      }
      hamburgerLight: file(relativePath: { eq: "icon/hamburger-light.svg" }) {
        publicURL
      }
    }
  `);

  const { pathname, search } = useLocation();
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);
  const [navMode, setNavMode] = useState("dark");

  const darkRoutesNav = ["/", "/klien-kami/", "/portofolio/"];
  const lightRoutesNav = [
    "/layanan-gratis/",
    "/cara-order/",
    "/bahan/",
    "/standar-ukuran/",
    "/tentang-kami/",
  ];

  const handleToggleNav = () => {
    setNavIsOpen(!navIsOpen);
  };

  useLayoutEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 80) {
        setIsTransparent(false);

        if (lightRoutesNav.includes(pathname)) {
          setNavMode("dark");
        }
      } else {
        setIsTransparent(true);

        if (lightRoutesNav.includes(pathname)) {
          setNavMode("light");
        }
      }
    };

    onScroll();

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (darkRoutesNav.includes(pathname)) {
      setNavMode("dark");
    } else if (lightRoutesNav.includes(pathname)) {
      setNavMode("light");
    } else {
      setNavMode("dark");
    }
  }, [pathname]);

  const navIsActive = route => {
    return route === pathname ? "active" : "";
  };

  const renderRoutes = () => {
    return router.slice(0, 8).map((route, idx) => (
      <Link
        key={idx}
        to={`${route.path}${search}`}
        className={`nav-link nav-link-custom ${navIsActive(route.path)}`}
      >
        {route.label}
      </Link>
    ));
  };

  const removeNavOnRoutes =
    pathname.split("/")[1] === "hubungi-kami" ||
    pathname.split("/")[1] === "contact"
      ? "d-none"
      : "";

  return (
    <nav
      className={`navbar navbar-expand-lg text-uppercase fixed-top ${
        isTransparent ? "bg-transparent" : "bg-white shadow"
      } ${removeNavOnRoutes} ${navMode}`}
      style={{ transition: "ease .25s" }}
    >
      <Link to="/" className="navbar-brand d-flex mr-3">
        {navMode === "dark" ? (
          <Img fixed={img.darkLogo.childImageSharp.fixed} />
        ) : (
          <Img fixed={img.lightLogo.childImageSharp.fixed} />
        )}
      </Link>
      <button
        type="button"
        className="navbar-toggler collapsed"
        onClick={handleToggleNav}
      >
        <span
          className="navbar-toggler-icon"
          style={{
            backgroundImage: `url(${
              navMode === "dark"
                ? img.hamburgerDark.publicURL
                : img.hamburgerLight.publicURL
            })`,
          }}
        ></span>
      </button>
      <div className="navbar-collapse collapse w-100 desktop-menu">
        <div className="navbar-nav justify-content-around mx-auto w-75 main-menu">
          {renderRoutes()}
        </div>
        <div className="navbar-nav justify-content-end w-25">
          <Link
            to={`/hubungi-kami/${search}`}
            className="nav-link nav-link-custom nav-link-contact-us"
          >
            <Img fixed={img.contactUsBtn.childImageSharp.fixed} />
          </Link>
        </div>
      </div>
      <div className={`mobile-menu ${navIsOpen ? `show` : ``}`}>
        <div className={`w-25 logo-container ${navIsOpen ? `show` : ``}`}>
          <Img fixed={img.lightLogo.childImageSharp.fixed} className="logo" />
        </div>
        <div className={`w-75 main-menu ${navIsOpen ? `show` : ``}`}>
          <div className="close-nav-btn" onClick={handleToggleNav}>
            <X size={30} />
          </div>
          <div className="navbar-nav">
            {renderRoutes()}
            <Link
              to="/hubungi-kami"
              className="nav-link nav-link-custom nav-link-contact-us"
            >
              Hubungi Kami
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
