import React, { useLayoutEffect, useState } from "react";
import SEO from "@components/SEO";
import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import { Link, graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import { Button } from "react-bootstrap";
import Img from "gatsby-image";

const Layout = ({ children, noFooter = false }) => {
  const { pathname, search } = useLocation();
  const [showContactBtn, setShowContactBtn] = useState(false);
  const img = useStaticQuery(graphql`
    {
      contactUsBtn: file(relativePath: { eq: "contact-us-btn.png" }) {
        childImageSharp {
          fluid(maxHeight: 185, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);



  useLayoutEffect(() => {

    const onResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    const onScroll = () => {
      if (window.scrollY > 100) {
        setShowContactBtn(true);
      } else {
        setShowContactBtn(false);
      }
    };

    onScroll();
    onResize();

    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div id="layout-container">
      <SEO />
      <Navbar />
      {children}
      {!noFooter && <Footer />}
      <Button
        as={Link}
        to={`/hubungi-kami/${search}`}
        className={`floating-contact-us ${showContactBtn ? `show` : ``}`}
      >
        <Img fluid={img.contactUsBtn.childImageSharp.fluid} />
      </Button>
    </div>
  );
};

export default Layout;
