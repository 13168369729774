import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import css from "./footer.module.scss";

const Footer = () => {
  const logo = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo/logo-dark.png" }) {
        childImageSharp {
          fixed(width: 180, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <div className={css.footer}>
      <Img fixed={logo.file.childImageSharp.fixed} className={css.logo} />
      <p className={css.about}>
        Berlokasi di Jakarta, kami adalah perusahaan konveksi jaket yang sudah
        berpengalaman selama bertahun-tahun menangani kebutuhan jaket. Kami
        memiliki tim costumer service yang mampu merespon dengan cepat segala
        pertanyaan maupun hal-hal yang ingin diketahui oleh klien. Dengan
        seleksi ketat yang kami lakukan, kami telah memiliki penjahit – penjahit
        jaket yang memiliki kual- itas jahitan rapi khas tailor. Ditambah dengan
        tim quality control yang berpengalaman, kami bisa menjamin dan
        memastikan bahwa barang yang dipesan sesuai dengan keinginan klien baik,
        kerapi- an, ukuran maupun model jaket. Untuk menyelesaikan problem waktu
        pengerjaan jaket yang molor, kami telah membentuk tim manajemen produksi
        yang tertata sehingga mampu mendeliver order klien tepat waktu. Kami
        memiliki kapasitas produksi besar, sehingga mampu melayani order banyak
        klien dalam waktu yang bersamaan. Berkenaan dengan bahan material jaket,
        kami mem- berikan garansi bahan material maupun warna sesuai dengan yang
        disepakati dengan klien di awal. Dan tentunya, kami bisa memberikan
        harga yang terjangkau, tanpa mengurangi kualitas bahan dan
        kualitasjahitan. Silakan hubungi CS kami, dengan senang hati CS kami
        akan melayani serta menjawab segala pertanyaan Anda.
      </p>
      <p className={css.address}>
        Workshop:{" "}
        <span style={{ color: "#f3611b" }}>
          Jalan Dewi Sartika No.38 Pancoran Mas Depok, Kota Depok, Jawa Barat
          16431
        </span>
      </p>
    </div>
  );
};

export default Footer;
